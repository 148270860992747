var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/useConstructor.ts
import { useRef } from "react";
var useConstructor = (callback) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current)
    return;
  callback();
  hasBeenCalled.current = true;
};

// src/useDebounce.ts
import { useEffect, useState } from "react";
var USE_DEBOUNCE_DEFAULT_TIMER = 500;
var useDebounce = (value, delay = USE_DEBOUNCE_DEFAULT_TIMER) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

// src/useEventCallback.ts
import { useCallback, useRef as useRef2 } from "react";

// src/useIsoEffect.ts
import { useEffect as useEffect2, useLayoutEffect } from "react";
var useIsoEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect2;

// src/useEventCallback.ts
var useEventCallback = (fn) => {
  const ref = useRef2(() => {
    throw new Error("Cannot call an event handler while rendering.");
  });
  useIsoEffect(() => {
    ref.current = fn;
  }, [fn]);
  return useCallback((...args) => ref.current(...args), [ref]);
};

// src/useEventListener.ts
import { useEffect as useEffect3, useRef as useRef3 } from "react";
function useEventListener(eventName, handler, element, options) {
  const savedHandler = useRef3(handler);
  useIsoEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect3(() => {
    var _a;
    const targetElement = (_a = element == null ? void 0 : element.current) != null ? _a : window;
    if (!(targetElement && targetElement.addEventListener))
      return;
    const listener = (event) => savedHandler.current(event);
    targetElement.addEventListener(eventName, listener, options);
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}

// src/useFocusWIthin.ts
import { useEffect as useEffect4, useState as useState2 } from "react";
var useFocusWithin = (ref) => {
  const [isFocusWithin, setIsFocusWithin] = useState2(false);
  useEffect4(() => {
    const onClick = () => {
      if (ref.current) {
        setIsFocusWithin(ref.current.contains(document.activeElement));
      }
    };
    const onTabPress = (e) => {
      if (ref.current && e.key === "Tab") {
        setIsFocusWithin(ref.current.contains(document.activeElement));
      }
    };
    if (ref.current) {
      document.addEventListener("click", onClick);
      document.addEventListener("keyup", onTabPress);
    }
    return () => {
      document.removeEventListener("click", onClick);
      document.removeEventListener("keyup", onTabPress);
    };
  }, [ref]);
  return isFocusWithin;
};

// src/useInput.ts
import { useState as useState3, useEffect as useEffect5 } from "react";
function useInput(first = void 0, second = void 0) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const [value, _setValue] = useState3(first != null ? first : void 0);
  const [loading, _setLoading] = useState3((_a = second == null ? void 0 : second.loading) != null ? _a : false);
  const [disabled, _setDisabled] = useState3((_b = second == null ? void 0 : second.disabled) != null ? _b : false);
  const [error, _setError] = useState3((_c = second == null ? void 0 : second.error) != null ? _c : "");
  const [label, _setLabel] = useState3((_d = second == null ? void 0 : second.label) != null ? _d : "");
  const [placeholder, _setPlaceholder] = useState3((_e = second == null ? void 0 : second.placeholder) != null ? _e : void 0);
  const [maxlength, _setMaxlength] = useState3((_f = second == null ? void 0 : second.maxlength) != null ? _f : void 0);
  const [charCounter, _setCharCounter] = useState3((_g = second == null ? void 0 : second.charCounter) != null ? _g : false);
  const [required, _setRequired] = useState3((_h = second == null ? void 0 : second.required) != null ? _h : false);
  const [onChange, _setOnChange] = useState3(
    (second == null ? void 0 : second.onChange) ? () => second.onChange : void 0
  );
  const [onFocus, _setOnFocus] = useState3((second == null ? void 0 : second.onFocus) ? () => second.onFocus : void 0);
  const [onBlur, _setOnBlur] = useState3((second == null ? void 0 : second.onBlur) ? () => second.onBlur : void 0);
  const [icon, _setIcon] = useState3();
  const [mask, _setMask] = useState3((_i = second == null ? void 0 : second.mask) != null ? _i : "");
  const [id, _setId] = useState3((_j = second == null ? void 0 : second.id) != null ? _j : "");
  useEffect5(() => {
    setOnChange((second == null ? void 0 : second.onChange) ? () => second.onChange : void 0);
    setOnBlur((second == null ? void 0 : second.onBlur) ? () => second.onBlur : void 0);
  }, [value]);
  const setValue = (value2) => {
    _setValue(value2);
    return setters;
  };
  const setLoading = (loading2) => {
    _setLoading(loading2);
    return setters;
  };
  const setDisabled = (disabled2) => {
    _setDisabled(disabled2);
    return setters;
  };
  const setError = (error2) => {
    _setError(error2);
    return setters;
  };
  const setLabel = (label2) => {
    _setLabel(label2);
    return setters;
  };
  const setPlaceholder = (placeholder2) => {
    _setPlaceholder(placeholder2);
    return setters;
  };
  const setMaxlength = (maxlength2) => {
    _setMaxlength(maxlength2);
    return setters;
  };
  const setCharCounter = (charCounter2) => {
    _setCharCounter(charCounter2);
    return setters;
  };
  const setRequired = (required2) => {
    _setRequired(required2);
    return setters;
  };
  const setOnChange = (onChange2) => {
    _setOnChange(onChange2);
    return setters;
  };
  const setOnFocus = (onFocus2) => {
    _setOnFocus(onFocus2);
    return setters;
  };
  const setOnBlur = (onBlur2) => {
    _setOnBlur(onBlur2);
    return setters;
  };
  const setIcon = (icon2) => {
    _setIcon(icon2);
    return setters;
  };
  const setMask = (mask2) => {
    _setMask(mask2);
    return setters;
  };
  const setId = (id2) => {
    _setId(id2);
    return setters;
  };
  const setters = {
    setValue,
    setLoading,
    setDisabled,
    setError,
    setLabel,
    setPlaceholder,
    setMaxlength,
    setCharCounter,
    setRequired,
    setOnChange,
    setOnFocus,
    setOnBlur,
    setIcon,
    setMask,
    setId
  };
  return __spreadValues({
    value,
    loading,
    disabled,
    error,
    label,
    placeholder,
    maxlength,
    charCounter,
    required,
    onChange,
    onFocus,
    onBlur,
    icon,
    mask,
    id
  }, setters);
}

// src/useInterval.ts
import { useEffect as useEffect6, useRef as useRef4 } from "react";
var useInterval = (callback, delay) => {
  const intervalRef = useRef4(null);
  const callbackRef = useRef4(callback);
  useEffect6(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect6(() => {
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
      return () => {
        window.clearInterval(intervalRef.current || 0);
      };
    }
  }, [delay]);
  return intervalRef;
};

// src/useLocalStorage.ts
import { useCallback as useCallback2, useEffect as useEffect7, useState as useState4 } from "react";
var useLocalStorage = (key, initialValue) => {
  const readValue = useCallback2(() => {
    if (typeof window === "undefined")
      return initialValue;
    try {
      const item = window.localStorage.getItem(key);
      return item ? parseJSON(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key \u201C${key}\u201D:`, error);
      return initialValue;
    }
  }, [initialValue, key]);
  const [storedValue, setStoredValue] = useState4(readValue);
  const setValue = useEventCallback((value) => {
    if (typeof window === "undefined")
      console.warn(`Tried setting localStorage key \u201C${key}\u201D even though environment is not a client`);
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(new Event("local-storage"));
    } catch (error) {
      console.warn(`Error setting localStorage key \u201C${key}\u201D:`, error);
    }
  });
  useEffect7(() => {
    setStoredValue(readValue());
  }, []);
  const handleStorageChange = useCallback2(
    (event) => {
      if ((event == null ? void 0 : event.key) && event.key !== key)
        return;
      setStoredValue(readValue());
    },
    [key, readValue]
  );
  useEventListener("storage", handleStorageChange);
  useEventListener("local-storage", handleStorageChange);
  return [storedValue, setValue];
};
var parseJSON = (value) => {
  try {
    return value === "undefined" ? void 0 : JSON.parse(value != null ? value : "");
  } catch (e) {
    console.log("parsing error on", { value });
    return void 0;
  }
};

// src/useOnOutsideClick.ts
import { useEffect as useEffect8 } from "react";
var useOnOutsideClick = (ref, handler) => {
  useEffect8(() => {
    const listener = (ev) => {
      if (!ref.current || ref.current.contains(ev.target))
        return;
      handler(ev);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

// src/usePrevious.ts
import { useRef as useRef5, useEffect as useEffect9 } from "react";
var usePrevious = (value) => {
  const ref = useRef5();
  useEffect9(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// src/useRest.ts
import { useEffect as useEffect10, useState as useState5 } from "react";
var useRest = (promise, cleanUp) => {
  const [promiseStatus, setPromiseStatus] = useState5({
    loading: false,
    error: false,
    data: null
  });
  useEffect10(() => {
    setPromiseStatus((status) => __spreadProps(__spreadValues({}, status), {
      error: false,
      loading: true
    }));
    const runPromise = () => __async(void 0, null, function* () {
      const data = yield promise();
      setPromiseStatus((status) => __spreadProps(__spreadValues({}, status), {
        data: data.body,
        loading: false,
        error: data.error
      }));
    });
    runPromise();
    return cleanUp == null ? void 0 : cleanUp();
  }, [promise, cleanUp]);
  return __spreadValues({}, promiseStatus);
};

// src/useScrollThreshold.ts
import { useState as useState6, useEffect as useEffect11 } from "react";
var useScrollThreshold = (threshold) => {
  const [hasScrolled, setHasScrolled] = useState6(false);
  const handleScroll = () => {
    const scrolled = window.pageYOffset !== void 0 ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const _hasScrolled = scrolled > threshold;
    if (hasScrolled !== _hasScrolled)
      setHasScrolled(_hasScrolled);
  };
  useEffect11(() => {
    handleScroll();
  }, []);
  useEffect11(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);
  return hasScrolled;
};

// src/useSessionStorage.ts
import { useCallback as useCallback3, useEffect as useEffect12, useState as useState7 } from "react";
var useSessionStorage = (key, initialValue) => {
  const readValue = useCallback3(() => {
    if (typeof window === "undefined")
      return initialValue;
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? parseJSON2(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading sessionStorage key \u201C${key}\u201D:`, error);
      return initialValue;
    }
  }, [initialValue, key]);
  const [storedValue, setStoredValue] = useState7(readValue);
  const setValue = useEventCallback((value) => {
    if (typeof window == "undefined")
      console.warn(`Tried setting sessionStorage key \u201C${key}\u201D even though environment is not a client`);
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.sessionStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(new Event("session-storage"));
    } catch (error) {
      console.warn(`Error setting sessionStorage key \u201C${key}\u201D:`, error);
    }
  });
  useEffect12(() => {
    setStoredValue(readValue());
  }, []);
  const handleStorageChange = useCallback3(
    (event) => {
      if ((event == null ? void 0 : event.key) && event.key !== key)
        return;
      setStoredValue(readValue());
    },
    [key, readValue]
  );
  useEventListener("storage", handleStorageChange);
  useEventListener("session-storage", handleStorageChange);
  return [storedValue, setValue];
};
var parseJSON2 = (value) => {
  try {
    return value === "undefined" ? void 0 : JSON.parse(value != null ? value : "");
  } catch (e) {
    console.log("parsing error on", { value });
    return void 0;
  }
};

// src/useTimeout.ts
import { useEffect as useEffect13, useRef as useRef6 } from "react";
var useTimeout = (callback, delay) => {
  const timeoutRef = useRef6(null);
  const callbackRef = useRef6(callback);
  useEffect13(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect13(() => {
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
      return () => {
        window.clearTimeout(timeoutRef.current || 0);
      };
    }
  }, [delay]);
  return timeoutRef;
};

// src/useForceRender.ts
import { useState as useState8 } from "react";
var useForceRender = () => {
  const [, setValue] = useState8(0);
  return () => setValue((v) => v + 1);
};

// src/useIsMobile.ts
import { useState as useState9, useEffect as useEffect14 } from "react";
var useIsMobile = (threshold = 1280) => {
  const [isMobile, setIsMobile] = useState9(true);
  const handleResize = () => {
    const _isMobile = window.innerWidth < threshold;
    if (_isMobile !== isMobile) {
      setIsMobile(_isMobile);
    }
  };
  useEffect14(() => {
    handleResize();
  }, []);
  useEffect14(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return isMobile;
};

// src/useSpyScrollArea.ts
import { useEffect as useEffect15, useState as useState10 } from "react";
var useSpyScrollArea = (elements, area) => {
  const [activeElement, setActiveElement] = useState10("");
  const spyElementsOnScrollArea = () => {
    elements.current.forEach((element) => {
      if (!element.current)
        return;
      const position = element.current.getBoundingClientRect().top;
      if (position >= area.start && position <= area.end) {
        setActiveElement(element.current.id);
      }
    });
  };
  useEffect15(() => {
    document.addEventListener("scroll", spyElementsOnScrollArea);
    return () => document.removeEventListener("scroll", spyElementsOnScrollArea);
  }, [activeElement]);
  return [activeElement, setActiveElement];
};

// src/useIsFirstRender.ts
import { useRef as useRef7 } from "react";
var useIsFirstRender = () => {
  const isFirst = useRef7(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
};

// src/useUpdateEffect.ts
import { useEffect as useEffect16 } from "react";
var useUpdateEffect = (effect, deps) => {
  const isFirst = useIsFirstRender();
  useEffect16(() => {
    if (!isFirst) {
      return effect();
    }
  }, deps);
};

// src/useOS.ts
import { useState as useState11, useEffect as useEffect17 } from "react";
var useOS = () => {
  const [userAgent, setUserAgent] = useState11();
  const [platform, setPlatform] = useState11();
  const iosPlatforms = ["iphone", "ipad", "ipod"];
  const macPlataforms = ["macintosh", "macintel", "macppc", "mac68k", "mac"];
  const windowsPlataforms = ["win32", "win64", "windows", "wince"];
  useEffect17(() => {
    var _a, _b;
    setUserAgent((_a = window.navigator) == null ? void 0 : _a.userAgent);
    setPlatform((_b = window.navigator) == null ? void 0 : _b.platform);
  }, []);
  const osData = {
    isAndroid: false,
    isiOS: false,
    isWindows: false,
    isMac: false,
    isLinux: false,
    isMobile: false,
    isApple: false
  };
  if (userAgent && /android/i.test(userAgent.toLowerCase()))
    osData.isAndroid = true;
  if (platform && iosPlatforms.indexOf(platform.toLowerCase()) !== -1 || userAgent && iosPlatforms.indexOf(userAgent.toLowerCase()) !== -1)
    osData.isiOS = true;
  if (userAgent && windowsPlataforms.indexOf(userAgent.toLowerCase()) !== -1 || platform && windowsPlataforms.indexOf(platform.toLowerCase()) !== -1)
    osData.isWindows = true;
  if (platform && macPlataforms.indexOf(platform.toLowerCase()) !== -1 || userAgent && macPlataforms.indexOf(userAgent.toLowerCase()) !== -1)
    osData.isMac = true;
  if (userAgent && /linux/i.test(userAgent.toLowerCase()))
    osData.isLinux = true;
  if (osData.isAndroid || osData.isiOS)
    osData.isMobile = true;
  if (osData.isiOS || osData.isMac)
    osData.isApple = true;
  return osData;
};

// src/useTrapFocus.ts
import { useEffect as useEffect18 } from "react";
var useTrapFocus = (containerRef) => {
  const handleFocus = (event) => {
    var _a;
    const focusableElements = (_a = containerRef.current) == null ? void 0 : _a.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    if (!focusableElements)
      return;
    const firstElement = focusableElements == null ? void 0 : focusableElements[0];
    const lastElement = focusableElements == null ? void 0 : focusableElements[(focusableElements == null ? void 0 : focusableElements.length) - 1];
    let isTab = event.key === "Tab";
    if (!isTab)
      return;
    if (event.shiftKey) {
      if (document.activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      }
    }
  };
  useEffect18(() => {
    var _a;
    (_a = containerRef.current) == null ? void 0 : _a.addEventListener("keydown", handleFocus);
    return () => {
      var _a2;
      (_a2 = containerRef.current) == null ? void 0 : _a2.removeEventListener("keydown", handleFocus);
    };
  }, []);
};

// src/useKeyboardStatus.ts
import { useEffect as useEffect19, useState as useState12 } from "react";
var MIN_KEYBOARD_SIZE = 300;
var useKeyboardStatus = () => {
  const [keyboardStatus, setKeyboardStatus] = useState12({
    isOpen: false,
    height: 0
  });
  useEffect19(() => {
    function handleResize() {
      var _a;
      const bodyHeight = window.screen.height;
      const windowHeight = ((_a = window == null ? void 0 : window.visualViewport) == null ? void 0 : _a.height) || window.innerHeight;
      const keyboardOpen = bodyHeight - MIN_KEYBOARD_SIZE > windowHeight;
      const keyboardHeight = bodyHeight - windowHeight;
      setKeyboardStatus({ isOpen: keyboardOpen, height: keyboardHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return keyboardStatus;
};

// src/useIsOnScreen.ts
import { useEffect as useEffect20, useState as useState13 } from "react";
var useIsOnScreen = (ref, options = {}) => {
  const [isIntersecting, setIsIntersecting] = useState13(false);
  let { rootMargin, threshold } = options;
  if (!rootMargin)
    rootMargin = "0px";
  if (!threshold)
    threshold = 1;
  useEffect20(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin, threshold }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return isIntersecting;
};

// src/useScrollDirection.ts
import { useState as useState14, useEffect as useEffect21 } from "react";
var useScrollDirection = () => {
  const [scrollDir, setScrollDir] = useState14("");
  useEffect21(() => {
    let prevScrollY = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (prevScrollY < currentScrollY) {
        setScrollDir("down");
      } else if (prevScrollY > currentScrollY) {
        setScrollDir("up");
      }
      prevScrollY = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollDir;
};

// src/useBrowser.ts
var Browser = /* @__PURE__ */ ((Browser2) => {
  Browser2["Chrome"] = "Chrome";
  Browser2["Safari"] = "Safari";
  Browser2["Firefox"] = "Firefox";
  Browser2["Edge"] = "Edg";
  Browser2["Opera"] = "OPR";
  Browser2["Default"] = "Default";
  return Browser2;
})(Browser || {});
var useBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const chrome = userAgent.indexOf("Chrome" /* Chrome */) > -1;
  const safari = userAgent.indexOf("Safari" /* Safari */) > -1;
  const firefox = userAgent.indexOf("Firefox" /* Firefox */) > -1;
  const edge = userAgent.indexOf("Edg" /* Edge */) > -1;
  const opera = userAgent.indexOf("OPR" /* Opera */) > -1;
  if (edge)
    return "Edg" /* Edge */;
  if (opera)
    return "OPR" /* Opera */;
  if (firefox)
    return "Firefox" /* Firefox */;
  if (safari) {
    if (chrome)
      return "Chrome" /* Chrome */;
    return "Safari" /* Safari */;
  }
  return "Default" /* Default */;
};

// ../maverick-utils/dist/index.mjs
var GetCookie = (name) => {
  var _a;
  const cookie = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return cookie ? (_a = cookie.pop()) != null ? _a : "" : "";
};
var Slugify = (value, options) => {
  const a3 = "\xE0\xE1\xE2\xE4\xE6\xE3\xE5\u0101\u0103\u0105\xE7\u0107\u010D\u0111\u010F\xE8\xE9\xEA\xEB\u0113\u0117\u0119\u011B\u011F\u01F5\u1E27\xEE\xEF\xED\u012B\u012F\xEC\u0142\u1E3F\xF1\u0144\u01F9\u0148\xF4\xF6\xF2\xF3\u0153\xF8\u014D\xF5\u0151\u1E55\u0155\u0159\xDF\u015B\u0161\u015F\u0219\u0165\u021B\xFB\xFC\xF9\xFA\u016B\u01D8\u016F\u0171\u0173\u1E83\u1E8D\xFF\xFD\u017E\u017A\u017C\xB7/_,:;";
  const b2 = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a3.split("").join("|"), "g");
  value = value.toString().toLocaleLowerCase();
  if (!(options == null ? void 0 : options.keepSpaces))
    value = value.replace(/\s+/g, "-");
  if (!(options == null ? void 0 : options.keepSpecialCharacters))
    value = value.replace(p, (c2) => b2.charAt(a3.indexOf(c2)));
  if (!(options == null ? void 0 : options.keepAnd))
    value = value.replace(/&/g, "-and-");
  if (!(options == null ? void 0 : options.keepNonWordCharacters)) {
    if (options == null ? void 0 : options.keepSpaces)
      value = value.replace(/[^\w^\s]+/g, "");
    else
      value = value.replace(/[^\w-]+/g, "");
  }
  if (!(options == null ? void 0 : options.keepMultipleDashes))
    value = value.replace(/--+/g, "-");
  return value.replace(/^-+/, "").replace(/-+$/, "");
};
var UtilsMoneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});
var UtilsGetProductTag = (product) => {
  var _a, _b;
  const tag = (_b = (_a = product == null ? void 0 : product.metadata) == null ? void 0 : _a.find((metadata) => metadata.key == "product-highlight-display")) == null ? void 0 : _b.value;
  if (!tag)
    return null;
  return tag === "app-only" ? "app_only" : tag;
};
var GaEvent = class {
  constructor() {
    this.Global = new Global();
    this.getItemBrand = (brand) => {
      return brand === process.env.BRAND_CORPORATE ? "corporate" : "franchise";
    };
    this.formatStringToAlphanumeric = (string) => {
      const lowerCaseString = string.toLowerCase();
      const alphanumericString = lowerCaseString.replace(/[^a-z0-9]/gi, " ");
      return alphanumericString.trim();
    };
    this.getItemModifiers = (product) => {
      let variant = "";
      product.choices.forEach((choice, index) => {
        const delimiter = index === 0 ? "" : "~";
        const optionString = `${delimiter}${choice.name}|${choice.id}|${choice.cost}|${choice.quantity}`;
        variant += optionString;
      });
      return variant || null;
    };
    this.getItemVariablePrice = (product) => {
      const isPriceVariable = product.choices.some((choice) => {
        return choice.cost > 0;
      });
      return isPriceVariable ? "variable" : "fixed";
    };
  }
  push(obj) {
    var _a, _b;
    (_b = (_a = window == null ? void 0 : window.dataLayer) == null ? void 0 : _a.push) == null ? void 0 : _b.call(_a, __spreadValues(__spreadValues({}, this.Global), obj));
  }
  getPageTitle() {
    if (typeof window !== "undefined") {
      return document.title;
    }
    return "";
  }
  CarouselClick(description) {
    this.push({
      event_label: description,
      event_action: `carousel|${window.location.pathname}`,
      event_category: "site promotion",
      event: "promo_click"
    });
  }
  CarouselView(pageTitle) {
    this.push({
      event_label: pageTitle,
      event_action: "carousel view",
      event_category: "carousel",
      event: "carousel_view"
    });
  }
  PageView(pageTitle, prevPageTitle, pageType, siteSection) {
    this.push({
      event: "page_view",
      event_label: pageTitle,
      event_action: window.location.href,
      event_category: "pages",
      previous_page_title: prevPageTitle != null ? prevPageTitle : "entry_page",
      page_type: pageType,
      site_section: siteSection
    });
  }
  TopNavigation(label) {
    this.push({
      event: "top_navigation",
      event_label: label,
      event_action: "top nav",
      event_category: "navigation"
    });
  }
  FooterNavigation(label) {
    this.push({
      event: "footer_navigation",
      event_label: label,
      event_action: "footer nav",
      event_category: "navigation"
    });
  }
  DineRewardsSignUp() {
    this.push({
      event_category: "signup",
      event_label: "reward signup success",
      event_action: "reward signup success",
      event: "rewards_registration_success"
    });
  }
  InternalLinkClick(destinationUrl, label) {
    this.push({
      event_label: label ? label : window.location.href,
      event_action: destinationUrl,
      event_category: destinationUrl ? destinationUrl : "internal link click",
      event: "internal_link_click"
    });
  }
  OutboundLinkClick(destinationUrl) {
    this.push({
      event_label: window.location.href,
      event_action: destinationUrl,
      event_category: "outbound link click",
      event: "outbound_link_click"
    });
  }
  ProfileButtonsClick(buttonName) {
    this.push({
      event: "click",
      event_action: buttonName,
      event_category: "my_account_menu"
    });
  }
  SocialShareClicks(label) {
    this.push({
      event_label: label,
      event_action: "share",
      event_category: "social",
      event: "social_share"
    });
  }
  FormStart(formName, formLocation) {
    this.push({
      event_label: formName,
      event_action: "start",
      event_category: "form",
      event: "form_start",
      form_location: formLocation
    });
  }
  FormAttempt(formName, formLocation) {
    this.push({
      event_label: formName,
      event_action: "attempt",
      event_category: "form",
      event: "form_attempt",
      form_location: formLocation
    });
  }
  CheckoutSuccess(formName, formLocation) {
    this.push({
      event_label: formName,
      event_action: "success",
      event_category: "form",
      event: "form_success",
      form_location: formLocation
    });
  }
  FormSuccess(formName, formLocation, variant) {
    this.push({
      event_label: `dine_rewards_singup_form_` + formName,
      event_action: "success",
      event_category: "form",
      event: "form_success",
      form_location: formLocation,
      variant
    });
  }
  ErrorView(errorMessage, pageTitle) {
    this.push({
      event_label: Slugify(errorMessage, { keepSpaces: true }),
      event_action: pageTitle != null ? pageTitle : "",
      event_category: "error message",
      event: "error_message"
    });
  }
  GetOnTheListStart(storeId) {
    this.push({
      event_label: storeId,
      event_action: "start",
      event_category: "waitlist",
      event: "waitlist_start"
    });
  }
  GetOnTheListComplete(storeId, guestsAndTime) {
    this.push({
      event_label: storeId,
      event_action: guestsAndTime,
      event_category: "waitlist",
      event: "waitlist_complete"
    });
  }
  GetOnTheListNextStepButton(storeId, guestsAndTime) {
    this.push({
      event_label: storeId,
      event_action: guestsAndTime,
      event_category: "waitlist",
      event: "waitlist_button_click"
    });
  }
  GetOnTheListSubmitButton(storeId, guestsAndTime) {
    this.push({
      event_label: storeId,
      event_action: guestsAndTime,
      event_category: "waitlist",
      event: "waitlist_button_click"
    });
  }
  SpecialReservationStart(pageTitle) {
    this.push({
      event: "special_reservation_start",
      event_name: "special_reservation_start",
      event_category: "special_reservation",
      event_action: "start",
      method: pageTitle
    });
  }
  SpecialReservationDetailsSubmitted(pageTitle, action, storeId) {
    this.push({
      event: "special_reservation_details_submitted",
      event_name: "special_reservation_details_submitted",
      event_category: "special_reservation",
      event_action: action,
      method: pageTitle,
      event_label: storeId
    });
  }
  SpecialReservationSignupSuccess(pageTitle, storeId) {
    this.push({
      event: "special_reservation_signup_success",
      event_name: "special_reservation_signup_success",
      event_category: "special_reservation",
      event_action: "signup_success",
      method: pageTitle,
      event_label: storeId
    });
  }
  ViewItemList(category, brand) {
    const items = category.products.map((p) => {
      return {
        item_list_id: category.id,
        item_name: this.formatStringToAlphanumeric(p.name),
        item_id: p.id,
        product_tag: UtilsGetProductTag(p),
        item_brand: this.getItemBrand(brand),
        item_variant: null,
        item_category: category.name
      };
    });
    this.push({
      event: "view_item_list",
      items,
      event_label: location.pathname,
      event_action: "product impression",
      event_category: "shopping"
    });
  }
  ViewItemListProductSuggestion(products) {
    const items = products.map((p) => {
      return {
        item_name: this.formatStringToAlphanumeric(p.product.name),
        item_id: p.product.id,
        product_tag: p.productTag,
        item_brand: p.brand ? this.getItemBrand(p.brand) : null,
        item_variant: null,
        item_category: p.category
      };
    });
    const item_list_id = products.map((p) => {
      return {
        item_id: p.product.id
      };
    });
    this.push({
      event: "view_item_list",
      item_list_id,
      items,
      item_list_name: "dollar count suggestion",
      event_label: "ordersummary",
      event_action: "product impressions",
      event_category: "shopping"
    });
  }
  SelectItem(product, storeId, brand, category, eventName) {
    this.push({
      event: eventName,
      event_label: product.name.toUpperCase(),
      event_action: "product click",
      event_category: "shopping",
      items: [
        {
          item_variant: null,
          item_list_name: "menu page",
          item_list_id: storeId,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category)
        }
      ]
    });
  }
  ViewItemListCMS(category, brand) {
    const items = category.products.map((p) => {
      return {
        item_list_id: category.id,
        item_name: this.formatStringToAlphanumeric(p.name),
        item_id: p.id,
        product_tag: UtilsGetProductTag(p),
        item_brand: this.getItemBrand(brand),
        item_variant: null,
        item_category: category.name,
        item_list_name: `menu_${this.formatStringToAlphanumeric(category.name)}`
      };
    });
    this.push({
      event: "view_item_list",
      items,
      event_label: location.pathname,
      event_action: "product impression",
      event_category: "shopping",
      item_list_name: `menu_${this.formatStringToAlphanumeric(category.name)}`
    });
  }
  SelectItemCMS(product, category, brand) {
    this.push({
      event: "select_item",
      event_category: "shopping",
      event_action: "product click",
      event_label: product.name.toUpperCase(),
      items: [
        {
          item_list_id: category.id,
          item_variant: null,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category.name)
        }
      ]
    });
  }
  MenuProductPageClick(buttonName) {
    this.push({
      event: "menu_product_page_click",
      event_category: "menu",
      event_action: "product_page_click",
      event_label: buttonName
    });
  }
  SearchStoreCms(storeCount, searchTerm) {
    const resultsCount = storeCount > 0 ? storeCount.toString() : "zero";
    this.push({
      event: "search",
      event_category: "store",
      event_attributes: "search",
      attributes: {
        resultsCount,
        term: searchTerm
      }
    });
  }
  ViewItem(product, quantity, storeId, brand, category, totalCost, isVariable) {
    this.push({
      event: "view_item",
      event_label: product.name.toUpperCase(),
      event_action: "product view",
      event_category: "shopping",
      items: [
        {
          item_variant: isVariable ? "variable" : "fixed",
          item_list_name: "menu page",
          item_list_id: storeId,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category),
          quantity,
          price: product.cost
        }
      ],
      value: totalCost
    });
  }
  AddToCart(product, quantity, storeId, brand, category, totalCost, origin, isVariable) {
    this.push({
      event: "add_to_cart",
      event_label: product.name.toUpperCase(),
      event_action: "add_to_cart_impression",
      event_category: "cart",
      currency: "USD",
      items: [
        {
          item_variant: isVariable ? "variable" : "fixed",
          item_list_name: origin,
          item_list_id: storeId,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category),
          quantity,
          price: product.cost
        }
      ],
      value: totalCost
    });
  }
  RemoveFromCart(product, productTag, quantity, storeId, brand, category) {
    this.push({
      value: product.totalcost,
      event: "remove_from_cart",
      event_label: product.name.toUpperCase(),
      event_action: "remove from cart",
      event_category: "shopping",
      currency: "USD",
      items: [
        {
          item_variant: this.getItemVariablePrice(product),
          item_list_name: "menu page",
          item_list_id: storeId,
          item_id: product.productId,
          product_tag: productTag,
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category),
          quantity,
          price: product.basecost
        }
      ]
    });
  }
  ViewCart(basket, categories, brand) {
    const items = basket.products.map((product) => {
      const category = categories.find((c2) => c2.products.find((p) => p.id === product.productId));
      const prod = category == null ? void 0 : category.products.find((p) => p.id === product.productId);
      return {
        item_variant: this.getItemVariablePrice(product),
        item_list_name: null,
        item_list_id: basket.vendorid.toString(),
        product_tag: UtilsGetProductTag(prod),
        item_name: this.formatStringToAlphanumeric(product.name),
        item_brand: this.getItemBrand(brand),
        item_category: category ? this.formatStringToAlphanumeric(category == null ? void 0 : category.name) : "",
        price: product.totalcost,
        quantity: product.quantity
      };
    });
    this.push({
      value: basket.total,
      event: "view_cart",
      currency: "USD",
      items
    });
  }
  BeginCheckout(basket, categories) {
    var _a;
    const items = basket == null ? void 0 : basket.products.map((product) => {
      const category = categories.find((c2) => c2.products.find((p) => p.id === product.productId));
      const prod = category == null ? void 0 : category.products.find((p) => p.id === product.productId);
      return {
        item_id: product.productId,
        item_name: this.formatStringToAlphanumeric(product.name),
        item_category: category ? this.formatStringToAlphanumeric(category == null ? void 0 : category.name) : "",
        product_tag: UtilsGetProductTag(prod),
        price: product.totalcost,
        quantity: product.quantity
      };
    });
    const utensils_values = [
      "include utensils",
      "include fork",
      "include napkin",
      "include knife",
      "include spoon",
      "include straw"
    ];
    const utensils = [];
    const product_sales = [];
    for (const item of items) {
      if (utensils_values.includes(item.item_name)) {
        utensils.push(item);
      } else {
        product_sales.push(item);
      }
    }
    const coupon = (_a = basket.discounts.find((d3) => d3.type === "Coupon")) == null ? void 0 : _a.description;
    this.push({
      value: basket.total,
      event: "begin_checkout",
      currency: "USD",
      items: product_sales,
      include_utensils: utensils != null ? utensils : null,
      checkout_step_name: "begin checkout",
      checkout_step_number: 1,
      coupon: coupon != null ? coupon : null
    });
  }
  AddPaymentInfo(basket, productsTags, paymentType) {
    var _a;
    const items = basket.products.map((product, index) => {
      return {
        item_variant: this.getItemVariablePrice(product),
        item_list_name: null,
        item_list_id: basket.vendorid.toString(),
        product_tag: productsTags[index]
      };
    });
    const coupon = (_a = basket.discounts.find((d3) => d3.type === "Coupon")) == null ? void 0 : _a.description;
    this.push({
      payment_type: paymentType,
      checkout_step_name: "payment",
      checkout_step_number: 2,
      event: "add_payment_info",
      currency: "USD",
      value: basket.total,
      items,
      coupon: coupon != null ? coupon : null
    });
  }
  Purchase(handoffMethod, orderReceived, categories, products, paymentMethod, brand, paymentType, cardType) {
    var _a;
    const coupon = (_a = orderReceived.discounts.find((d3) => d3.type === "Coupon")) == null ? void 0 : _a.description;
    const itemBrand = this.getItemBrand(brand);
    const items = products.map((product) => {
      const category = categories.find((c2) => c2.products.find((p) => p.id === product.productId));
      const prod = category == null ? void 0 : category.products.find((p) => p.id === product.productId);
      return {
        item_list_name: "menu page",
        item_list_id: orderReceived.vendorid,
        item_id: product.productId,
        item_name: this.formatStringToAlphanumeric(product.name),
        item_brand: itemBrand,
        item_category: category == null ? void 0 : category.name.toLowerCase(),
        product_tag: UtilsGetProductTag(prod),
        price: product.totalcost,
        quantity: product.quantity,
        coupon: null,
        discount: null,
        item_category5: this.getItemVariablePrice(product),
        item_variant: this.getItemModifiers(product)
      };
    });
    const utensils_values = [
      "include utensils",
      "include fork",
      "include napkin",
      "include knife",
      "include spoon",
      "include straw"
    ];
    const utensils = [];
    const product_sales = [];
    for (const item of items) {
      if (utensils_values.includes(item.item_name)) {
        utensils.push(item);
      } else {
        product_sales.push(item);
      }
    }
    this.push({
      shipping_tier: handoffMethod,
      event: "purchase",
      currency: "USD",
      value: orderReceived.total,
      items: product_sales,
      include_utensils: utensils != null ? utensils : null,
      affiliation: paymentMethod,
      shipping: orderReceived.customerhandoffcharge,
      tax: orderReceived.salestax,
      transaction_id: orderReceived.id,
      coupon: coupon != null ? coupon : null,
      tip_amount: orderReceived.tip,
      event_category: "shopping",
      event_action: "purchase",
      payment_type: paymentType,
      credit_card_type: cardType
    });
  }
  ErrorMessage(errorMessage, pageTitle) {
    this.push({
      event: "error_message",
      event_label: this.formatStringToAlphanumeric(errorMessage),
      event_action: pageTitle != null ? pageTitle : this.getPageTitle(),
      event_category: "error message"
    });
  }
  AutoCompleteViewed() {
    this.push({
      event: "autocomplete_viewed",
      event_action: "view",
      event_category: "autocomplete"
    });
  }
  AutoCompleteClicked() {
    this.push({
      event: "autocomplete_clicked",
      event_action: "click",
      event_category: "autocomplete"
    });
  }
  SearchStore(addressSearched, storeCount, success, handoffMethod) {
    const resultCount = !success ? "zero" : storeCount > 0 ? storeCount.toString() : "zero";
    this.push({
      event: success ? "search_success" : "search_failure",
      event_label: addressSearched,
      event_category: "search:store",
      eventAttributes: {
        internal_search_destination: "search results page",
        search_result_count: resultCount,
        shipping_tier: handoffMethod
      }
    });
  }
  GeoLocationSuccess(storeId) {
    this.push({
      event_action: "geolocation allowed",
      event_category: "geolocation",
      event: "geo_location_success",
      event_label: storeId != null ? storeId : "no store ID|store not selected"
    });
  }
  GeoLocationFailure(storeId) {
    this.push({
      event_label: storeId != null ? storeId : "no store ID|store not selected",
      event_action: "geolocation denied",
      event_category: "geolocation",
      event: "geo_location_failure"
    });
  }
  GeoLocationDisplay(storeId) {
    this.push({
      event_label: storeId != null ? storeId : "no store ID|store not selected",
      event_action: "geolocation view",
      event_category: "geolocation",
      event: "geo_location_display"
    });
  }
  DineRewardsLinkClick(origin) {
    this.push({
      event: "dine_rewards_link_click",
      event_label: "join dine rewards",
      event_action: origin,
      event_category: "internal link click"
    });
  }
  UseMyLocationClick() {
    this.push({
      event_label: "use my location",
      event_category: "internal link click",
      event: "use_my_location_click"
    });
  }
  MapUse() {
    this.push({
      event_label: "map use",
      event_category: "map interaction",
      event: "map_use"
    });
  }
  MapSelectRestaurant(selectedRestaurant) {
    this.push({
      event_label: "map select restaurant",
      event_category: "map interaction",
      event: "map_click",
      selected_restaurant: selectedRestaurant
    });
  }
  SearchResultsPageClick(handoffMethod) {
    this.push({
      event_label: "order now",
      event_action: "button click",
      event: "order_now_search_page_clicks",
      event_category: "order now search page clicks",
      eventAttributes: {
        shipping_tier: handoffMethod
      }
    });
  }
  UserHasLoggedIn() {
    this.push({
      event: "login",
      event_label: null,
      event_action: "login",
      event_category: "account",
      logged_in_status: "logged in"
    });
  }
  UserHasLoggedOut() {
    this.push({
      event: "logout",
      event_label: null,
      event_action: "logout",
      event_category: "account",
      logged_in_status: "logout out"
    });
  }
  UserExpiringRewards(userEmail, expiringFifteenDays, expiringThirtyDays) {
    this.push({
      event_action: "expiring rewards",
      event: "user_has_expiring_rewards",
      next_15_days: expiringFifteenDays,
      next_30_days: expiringThirtyDays,
      user_email: userEmail
    });
  }
  GeneratedPoints(generatedPoints) {
    this.push({
      event: "rewards_points_increase",
      event_label: generatedPoints + "",
      event_action: "points increase",
      event_category: "rewards updated"
    });
  }
  RedeemRewardClick(origin, rewardValue) {
    this.push({
      event: "redeem_rewards",
      event_category: origin,
      event_action: `reward redeemed ${rewardValue}`
    });
  }
  RedeemRewardAppClick(origin, redirectedUrl) {
    this.push({
      event: "rewards_redeem_app_click",
      event_label: origin,
      event_action: `redeem app click`,
      event_category: "internal link click",
      redirected_url: redirectedUrl
    });
  }
  //TODO: Check for removal in January 2024
  RedeemRewardHighlightButtonClick(origin, redirectedUrl) {
    this.push({
      event: "feature_highlight",
      event_label: origin,
      event_action: `redeem app click`,
      event_category: "internal link click",
      redirected_url: redirectedUrl
    });
  }
  RedeemRedirectAppStore(origin, redirectedUrl) {
    this.push({
      event: "rewards_redeem_redirect_appstore",
      event_label: origin,
      event_action: `redeem redirect appStore`,
      event_category: "internal link click",
      redirected_url: redirectedUrl
    });
  }
  RecentAndFavesActive(userEmail) {
    this.push({
      event_action: "recent and faves active",
      event: "recent_and_faves_active",
      user_email: userEmail
    });
  }
  RecentAndFavesInactive(userEmail) {
    this.push({
      event_action: "recent and faves inactive",
      event: "recent_and_faves_inactive",
      user_email: userEmail
    });
  }
  FavoritesOrderAgainButtonClick() {
    this.push({
      event: "add_favorite",
      event_label: "/order/menu",
      event_action: "add|card click",
      event_category: "favorites"
    });
  }
  RecentOrdersOrderAgainButtonClick() {
    this.push({
      event: "add_recent_order",
      event_label: "/order/menu",
      event_action: "add|card click",
      event_category: "recent orders"
    });
  }
  //TODO: Check for removal in January 2024
  OrderAgainHighlightButtonClick(category) {
    this.push({
      event: "feature_highlight",
      event_label: "/order/menu",
      event_action: "add|card click",
      event_category: category
    });
  }
  FavoritesShowDetailsButtonClick() {
    this.push({
      event: "view_favorite",
      event_label: "/order/menu",
      event_action: "view|card click",
      event_category: "favorites"
    });
  }
  RecentOrdersShowDetailsButtonClick() {
    this.push({
      event: "view_recent_order",
      event_label: "/order/menu",
      event_action: "view|card click",
      event_category: "recent orders"
    });
  }
  FavoritesButtonClick(favoriteCount, origin, isGuest) {
    this.push({
      event: "favorite_button_click",
      event_action: "button_click",
      event_category: "favorite",
      event_label: isGuest ? origin : `${origin}|${favoriteCount ? favoriteCount : 0}`
    });
  }
  RecentOrdersButtonClick(recentOrdersCount, origin, isGuest) {
    this.push({
      event_label: isGuest ? origin : `${origin}|${recentOrdersCount ? recentOrdersCount : 0}`,
      event_action: "button_click",
      event: "recent_orders_button_click",
      event_category: "recent_orders"
    });
  }
  RecentOrdersButtonImpression(recentOrdersCount) {
    this.push({
      event: "recent_orders_button_impression",
      event_category: "recent orders",
      event_label: `menu|${recentOrdersCount ? recentOrdersCount : 0}`,
      event_action: "button impression"
    });
  }
  FavoritesButtonImpression(favoriteCount) {
    this.push({
      event: "favorite_button_impression",
      event_category: "favorites",
      event_label: `menu|${favoriteCount ? favoriteCount : 0}`,
      event_action: "button impression"
    });
  }
  SearchButtonClick() {
    this.push({
      event: "search_click",
      event_label: " search button click",
      event_action: "search_button_click",
      event_category: "search:store"
    });
  }
  GetDirectionsClick(origin) {
    this.push({
      event: "get_direction_click",
      event_label: "get direction",
      event_action: origin,
      event_category: "get_direction"
    });
  }
  SearchAreaClick() {
    this.push({
      event: "search_area_click",
      event_label: "search this area",
      event_action: "search this area click",
      event_category: "search:store"
    });
  }
  PromotionalBannerClick(buttonName, bannerId, eventLabel) {
    this.push({
      event: "internal_banner_click",
      event_label: eventLabel,
      event_category: "promotional_banner",
      banner_id: bannerId,
      button_name: buttonName
    });
  }
  AppOnlyAddToCart(deepLink) {
    this.push({
      event_label: "app only",
      event_action: deepLink,
      event: "app_only_add_to_cart",
      event_category: "app only"
    });
  }
  SaveCustomizationClick(customizedCategory, item) {
    this.push({
      event: "save_customization_click",
      event_category: "customization",
      event_action: [customizedCategory, item]
    });
  }
  WaitlistCriteriaMet() {
    this.push({
      event: "waitlist_criteria_met",
      event_action: "criteria met",
      event_category: "get on the list"
    });
  }
  DineRewardsActivityTimelineFilter(optionSelected) {
    this.push({
      event: "apply_activity_filter",
      event_category: "activity filter",
      event_action: "apply filter",
      event_label: optionSelected
    });
  }
  DineRewardsSideDrawerNavigation(buttonName) {
    this.push({
      event: "menu_navigation",
      event_category: "navigation",
      event_action: "menu",
      event_label: buttonName
    });
  }
  SaveCustomizationSuccess(customizedCategory, item) {
    this.push({
      event: "save_customization_success",
      event_category: "customization",
      event_action: [customizedCategory, item]
    });
  }
  DineRewardsPointsTracker(currentPoints) {
    this.push({
      event: "points_tracker_view",
      event_category: "points tracker",
      event_action: "view_points",
      event_label: currentPoints
    });
  }
  DineRewardsDetailReward(rewardName, brand) {
    this.push({
      event: "view-rewards-details",
      event_category: "view-detail",
      event_action: brand,
      event_label: rewardName
    });
  }
  DineRewardsMissingPointsFormSubmit(brand, storeId, responseTitle) {
    this.push({
      event: "request_missing_points_success",
      event_category: "missing points",
      event_action: brand,
      event_label: storeId,
      response_title: responseTitle
    });
  }
  DineRewardsMissingPointsFormBegin() {
    this.push({
      event: "request_missing_points_start",
      event_category: "missing points"
    });
  }
  visitWebsite(brandName) {
    this.push({
      event: "visit_website",
      event_category: "visit website",
      event_action: brandName
    });
  }
  getOnTheWaitList(brandName) {
    this.push({
      event: "get_on_the_waitlist",
      event_category: "get on the list",
      event_action: brandName
    });
  }
  GlobalEvent() {
    this.push({
      event: "global"
    });
  }
  HomepageButtonClick(buttonName) {
    this.push({
      event: "homepage_button_click",
      event_label: buttonName,
      event_action: "homepage button click",
      event_category: "navigation"
    });
  }
  InternalLinkClickDr(destinationUrl, name) {
    this.push({
      event_label: destinationUrl,
      event_action: name,
      event_category: "internal link click",
      event: "internal_link_click"
    });
  }
  StartProfileEdit() {
    this.push({
      event: "profile_change_start",
      event_category: "profile change",
      event_action: "start",
      event_label: "/dashboard/member-profile"
    });
  }
  ProfileEditSuccess() {
    this.push({
      event: "profile_change_success",
      event_category: "profile change",
      event_action: "success",
      event_label: "/dashboard/member-profile"
    });
  }
  ProfileEditFailure() {
    this.push({
      event: "profile_change_failure",
      event_category: "profile change",
      event_action: "failure",
      event_label: "/dashboard/member-profile"
    });
  }
  MenuNavigation(buttonName) {
    this.push({
      event: "menu_navigation",
      event_category: "menu navigation",
      event_action: "Logged menu",
      event_label: buttonName
    });
  }
  HomePageScroll(sectionName) {
    this.push({
      event: "homepage_scroll",
      event_category: "homepage",
      event_action: "Scroll",
      event_label: sectionName
    });
  }
  RewardRegistrationSuccess() {
    this.push({
      event: "rewards_registration_success",
      event_category: "signup",
      event_action: "Reward signup success"
    });
  }
  RegistrationUserSuccess(pageRequest) {
    this.push({
      event: "rewards_registration_success",
      event_category: "sign up",
      event_action: pageRequest
    });
  }
};
var Global = class {
  constructor() {
    this.store_id = "not selected";
    this.customer_id = null;
    this.logged_in_status = "not logged in";
    this.dine_rewards_user_id = null;
    this.ab_testing = null;
    this.dine_rewards_status = "unlinked";
    this.email_address_code = null;
    this.platform_name = "sitecore_maverick";
    this.dollar_rewards_available = null;
    this.event_category = null;
    this.event_label = null;
    this.event_action = null;
    this.page_type = null;
    this.previous_page_title = null;
    this.site_section = null;
    this.init();
  }
  init() {
    try {
      if ("localStorage" in window) {
        const stored = window.localStorage.getItem("ga-global");
        if (stored) {
          Object.assign(this, JSON.parse(stored));
        }
      }
    } catch (e) {
    }
  }
  Set(key, value) {
    this[key] = value;
    this.persist();
  }
  persist() {
    if ("localStorage" in window) {
      window.localStorage.setItem("ga-global", JSON.stringify(this));
    }
  }
};
var instance = new GaEvent();
function buildFormatLongFn(args) {
  return function() {
    var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var width = options.width ? String(options.width) : args.defaultWidth;
    var format2 = args.formats[width] || args.formats[args.defaultWidth];
    return format2;
  };
}
var dateFormats = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
var formatLong = {
  date: buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: "full"
  }),
  time: buildFormatLongFn({
    formats: timeFormats,
    defaultWidth: "full"
  }),
  dateTime: buildFormatLongFn({
    formats: dateTimeFormats,
    defaultWidth: "full"
  })
};
function buildLocalizeFn(args) {
  return function(dirtyIndex, options) {
    var context = options !== null && options !== void 0 && options.context ? String(options.context) : "standalone";
    var valuesArray;
    if (context === "formatting" && args.formattingValues) {
      var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
      var width = options !== null && options !== void 0 && options.width ? String(options.width) : defaultWidth;
      valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
    } else {
      var _defaultWidth = args.defaultWidth;
      var _width = options !== null && options !== void 0 && options.width ? String(options.width) : args.defaultWidth;
      valuesArray = args.values[_width] || args.values[_defaultWidth];
    }
    var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
    return valuesArray[index];
  };
}
var eraValues = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
};
var quarterValues = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
};
var monthValues = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
};
var dayValues = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
};
var dayPeriodValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
};
var formattingDayPeriodValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
};
var ordinalNumber = function ordinalNumber2(dirtyNumber, _options) {
  var number = Number(dirtyNumber);
  var rem100 = number % 100;
  if (rem100 > 20 || rem100 < 10) {
    switch (rem100 % 10) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
    }
  }
  return number + "th";
};
var localize = {
  ordinalNumber,
  era: buildLocalizeFn({
    values: eraValues,
    defaultWidth: "wide"
  }),
  quarter: buildLocalizeFn({
    values: quarterValues,
    defaultWidth: "wide",
    argumentCallback: function argumentCallback(quarter) {
      return quarter - 1;
    }
  }),
  month: buildLocalizeFn({
    values: monthValues,
    defaultWidth: "wide"
  }),
  day: buildLocalizeFn({
    values: dayValues,
    defaultWidth: "wide"
  }),
  dayPeriod: buildLocalizeFn({
    values: dayPeriodValues,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: "wide"
  })
};
function buildMatchFn(args) {
  return function(string) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var width = options.width;
    var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
    var matchResult = string.match(matchPattern);
    if (!matchResult) {
      return null;
    }
    var matchedString = matchResult[0];
    var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
    var key = Array.isArray(parsePatterns) ? findIndex(parsePatterns, function(pattern) {
      return pattern.test(matchedString);
    }) : findKey(parsePatterns, function(pattern) {
      return pattern.test(matchedString);
    });
    var value;
    value = args.valueCallback ? args.valueCallback(key) : key;
    value = options.valueCallback ? options.valueCallback(value) : value;
    var rest = string.slice(matchedString.length);
    return {
      value,
      rest
    };
  };
}
function findKey(object, predicate) {
  for (var key in object) {
    if (object.hasOwnProperty(key) && predicate(object[key])) {
      return key;
    }
  }
  return void 0;
}
function findIndex(array, predicate) {
  for (var key = 0; key < array.length; key++) {
    if (predicate(array[key])) {
      return key;
    }
  }
  return void 0;
}
function buildMatchPatternFn(args) {
  return function(string) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var matchResult = string.match(args.matchPattern);
    if (!matchResult)
      return null;
    var matchedString = matchResult[0];
    var parseResult = string.match(args.parsePattern);
    if (!parseResult)
      return null;
    var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
    value = options.valueCallback ? options.valueCallback(value) : value;
    var rest = string.slice(matchedString.length);
    return {
      value,
      rest
    };
  };
}
var matchOrdinalNumberPattern = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern = /\d+/i;
var matchEraPatterns = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
};
var parseEraPatterns = {
  any: [/^b/i, /^(a|c)/i]
};
var matchQuarterPatterns = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
};
var parseQuarterPatterns = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
};
var parseMonthPatterns = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
};
var parseDayPatterns = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
};
var parseDayPeriodPatterns = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
};
var match = {
  ordinalNumber: buildMatchPatternFn({
    matchPattern: matchOrdinalNumberPattern,
    parsePattern: parseOrdinalNumberPattern,
    valueCallback: function valueCallback(value) {
      return parseInt(value, 10);
    }
  }),
  era: buildMatchFn({
    matchPatterns: matchEraPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns,
    defaultParseWidth: "any"
  }),
  quarter: buildMatchFn({
    matchPatterns: matchQuarterPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns,
    defaultParseWidth: "any",
    valueCallback: function valueCallback2(index) {
      return index + 1;
    }
  }),
  month: buildMatchFn({
    matchPatterns: matchMonthPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns,
    defaultParseWidth: "any"
  }),
  day: buildMatchFn({
    matchPatterns: matchDayPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns,
    defaultParseWidth: "any"
  }),
  dayPeriod: buildMatchFn({
    matchPatterns: matchDayPeriodPatterns,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns,
    defaultParseWidth: "any"
  })
};

// src/useCookies.ts
import { useEffect as useEffect22, useState as useState15 } from "react";
var useCookies = (cookieName) => {
  const [cookies, setCookies] = useState15();
  useEffect22(() => {
    setCookies(GetCookie(cookieName));
    function onChange() {
      const newCookies = GetCookie(cookieName);
      if (newCookies !== cookies) {
        setCookies(newCookies);
      }
    }
    if ("cookieStore" in window) {
      window.cookieStore.addEventListener("change", onChange);
    } else {
      setInterval(onChange, 300);
    }
    return () => {
      if ("cookieStore" in window) {
        window.cookieStore.removeEventListener("change", onChange);
      }
    };
  });
  return [cookies];
};
export {
  Browser,
  USE_DEBOUNCE_DEFAULT_TIMER,
  useBrowser,
  useConstructor,
  useCookies,
  useDebounce,
  useEventCallback,
  useEventListener,
  useFocusWithin,
  useForceRender,
  useInput,
  useInterval,
  useIsFirstRender,
  useIsMobile,
  useIsOnScreen,
  useIsoEffect,
  useKeyboardStatus,
  useLocalStorage,
  useOS,
  useOnOutsideClick,
  usePrevious,
  useRest,
  useScrollDirection,
  useScrollThreshold,
  useSessionStorage,
  useSpyScrollArea,
  useTimeout,
  useTrapFocus,
  useUpdateEffect
};
